import {lazy} from 'react'
import React from 'react'

import {permission} from '../constants/permission'
import {AlgorithmicAnalytics, Configuration, ManagementDashboard} from '../styles/svg/productMenu/svgIcons'
import {MySubscriptions, P2PPlatform, QuantitativeIntelligence} from '../styles/svg/productMenu/svgIcons'
import {PortfolioAnalytics, DigitalTransformation, DataMining} from '../styles/svg/productMenu/svgIcons'
import {IconATYAbcPortfolio, IconATYAbcmAnalytics} from '../styles/svg/svgIcons'
import {IconATYInventoryManagemnet, IconDashboardChangePassword} from '../styles/svg/svgIcons'
import {IconExeTradingQueue, IconOperationsMenu, IconPricingTool} from '../styles/svg/svgIcons'
import {IconAccountSettingsGear, IconOperations} from '../styles/svg/svgIcons'
import {IconAYTPricingTool, IconAYTMaturityCalendar, IconAYTSettlementLadder} from '../styles/svg/svgIcons'
import {IconExeP2P, IconExeRepo, IconExeAvailabilityAndNeed, IconExeSBL} from '../styles/svg/svgIcons'
import {IconLocateSearch, IconIQDecisionMaking, IconClientScoring, IconIQSIMM} from '../styles/svg/svgIcons'
import {IconIndexTradingAndAnalytics, IconIQInventoryOptimization} from '../styles/svg/svgIcons'
import {IconPCCollateralBasket, IconAYTMarketData} from '../styles/svg/svgIcons'
import {IconDashboard, IconPortfolioManager} from '../styles/svg/svgIcons'
import {IconExecution, IconPortfolioAnalytics, IconPortfolioManagerTwo} from '../styles/svg/svgIcons'
import {IconAnalytics, IconDataMining, IconQuantitativeIntelligence} from '../styles/svg/svgIcons'

export const getErrorReportPath = (docId: string) => `/portfolio-manager/${docId}/error-report`
export const getSuccessReportPath = (docId: string) => `/portfolio-manager/${docId}/success-report`

const AccessDenied = lazy(() => import('../pages/accessDenied/accessDeniedComponent'))
const Iframe = lazy(() => import('../pages/iframe/IframeComponent'))
const AbcmResult = lazy(() => import('../pages/abcmResult/abcmResultContainer'))
const AbcmReusltTable = lazy(() => import('../pages/abcmResultTable/abcmResultContainer'))
const AbcPortfolio = lazy(() => import('../pages/abcPortfolio/abcPortfolioContainer'))
const AbcPortfolioTest = lazy(() => import('../pages/abcPortfolio/abcPortfolioTestContainer'))
const AccountSettings = lazy(() => import('../pages/accountSettingsRevamp/accoutSettingsContainer'))
const Abpm = lazy(() => import('../pages/ABPM/ABPMContainer'))
const ActivityType = lazy(() => import('../pages/common/ActivityType/ActivityTypeContainer'))
const AlpSttInventoryManager = lazy(() => import('../pages/alp/AlpSttInventoryManager/AlpSttInventoryMgmtContainer'))
const AlpSttAuthorizer = lazy(() => import('../pages/alp/AlpSttAuthorizer/AlpSttAuthorizerContainer'))
const AlpSttRequester = lazy(() => import('../pages/alp/AlpSttRequester/AlpSttRequesterContainer'))
const AlpAuctionsInternalMain = lazy(() => import('../pages/alp/Auctions/Internal/InternalMainContainer'))
const AlpAuctionsBorrowerMain = lazy(() => import('../pages/alp/Auctions/Borrower/BorrowerMainContainer'))
const BasketItems = lazy(
	() => import('../pages/collateralBasketList/collateralBasketItems/collateralBasketItemContainer')
)
const BreakEvenPricer = lazy(() => import('../pages/repo-breakeven-pricer/breakEvenPricerContainer'))
const Boxes = lazy(() => import('../pages/common/tradeDetails/boxesTrades/boxesComponentNew'))
const ChangePassword = lazy(() => import('../pages/changePassword/changePasswordContainer'))
const ClientScoring = lazy(() => import('../pages/ClientScoring/ClientScoringContainer'))
const CollateralBasket = lazy(() => import('../pages/collateralBasketList/collateralBasket/collateralBasketContainer'))
const CollateralBasketList = lazy(() => import('../pages/collateralBasketList/collateralBasketListContainer'))
const CollateralEligibilityMatrix = lazy(
	() => import('../pages/collateralEligibilityMatrix/CollateralEligibilityMatrixComponent')
)
const CounterpartyHaircut = lazy(() => import('../pages/CounterpartyHaircut/CounterpartyHaircutContainer'))
const CusipSchedule = lazy(() => import('../pages/CusipSchedule/CusipScheduleContainer'))
const DashBoard = lazy(() => import('../pages/dashboard/dashboardContainer'))
const DashboardWrapper = lazy(() => import('../pages/dashboard-test/dashboardTestContainer'))
const DecisionMaking = lazy(() => import('../pages/decisionMaking/decisionMakingContainer'))
const DecisionMakingDashboard = lazy(() => import('../pages/DecisionMakingDashboard/DecisionMakingDashboardContainer'))
const DummyWidget = lazy(() => import('../features/widget/DummyWidgetContainer'))
const CollateralOptWidget1 = lazy(() => import('../features/widget/CollateralOptWidget1/CollateralOptWidgetContainer1'))
const CollateralOptWidget2 = lazy(() => import('../features/widget/CollateralOptWidget2/CollateralOptWidgetContainer2'))
const ErrorHandler = lazy(() => import('../pages/errorHandler/errorContainer'))
const ErrorReport = lazy(() => import('../pages/errorReport/errorReportContainer'))
const ForgotPassword = lazy(() => import('../pages/forgotPassword/forgotPasswordContainer'))
const IconGuide = lazy(() => import('../pages/styleGuide/iconGuide'))
const Inventory = lazy(() => import('../pages/inventory/inventoryContainer'))
const InventoryOptimization = lazy(() => import('../pages/inventoryOptimization/inventoryOptimizationContainer'))
const IndexAnalysis = lazy(() => import('../pages/indexAnalysis/indexAnalysisContainer'))
const IndexPricing = lazy(() => import('../pages/indexPricing/IndexPricingContainer'))
const Lcr = lazy(() => import('../pages/LCR/LCRContainer'))
const Locate = lazy(() => import('../pages/Locate/LocateAuthorizer/LocateAuthorizerContainer'))
const LocateClient = lazy(() => import('../pages/Locate/LocateRequester/LocateRequesterContainer'))
const LocateInventoryManager = lazy(() => import('../pages/Locate/LocateInventoryManager/LocateInventoryMgmtContainer'))
const LocateSecurityManager = lazy(() => import('../pages/LocateSecurityManager/LocateSecurityManagerContainer'))
const CollateralSchedule = lazy(() => import('../pages/CollateralSchedule/CollateralScheduleContainer'))
const Login = lazy(() => import('../pages/login/loginContainer'))
const Tenant = lazy(() => import('../pages/login/TenantSelection/tenantSelectionContainer'))
const MarginAnalysis = lazy(() => import('@siteui/margin-analysis/dist/index'))
const MarginAttribution = lazy(() => import('@siteui/margin-analysis/dist/index'))
const MarginAttributionWidget = lazy(() => import('@siteui/margin-analysis/dist/index'))
const MarginAnalytics = lazy(() => import('../pages/marginAnalytics/marginAnalyticsContainer'))
const MarginManagement = lazy(() => import('../pages/MarginManagement/MarginManagementContainer'))
const MaturingTrades = lazy(() => import('../pages/common/tradeDetails/maturingTrades/maturingTradesContainer'))
const NeedsAvailabilities = lazy(() => import('../pages/needsAvailabilities/needsAvailabilitiesContainer'))
const NonCash = lazy(() => import('../pages/NonCash/NonCashContainer'))
const OpenTrades = lazy(() => import('../pages/common/tradeDetails/openTrades/openTradesContainer'))
const Operations = lazy(() => import('../pages/operations/operationsContainer'))
const PeerToPeer = lazy(() => import('../pages/PeerToPeer/PeerToPeerContainer'))
const PortfolioManager = lazy(() => import('../pages/portfolioManager/portfolioManagerContainer'))
const ProductMenu = lazy(() => import('../features/ProductMenu/ProductMenuContainer'))
const PdfViewer = lazy(() => import('../features/PdfViewer/PdfViewer'))
const Repo = lazy(() => import('../pages/repo/repoContainer'))
const RepoTest = lazy(() => import('../pages/repo-enhanced/repoWrapperContainer'))
const ReportsComponent = lazy(() => import('../pages/reports/reportsContainer'))
const Rerate = lazy(() => import('../pages/common/tradeDetails/ReRate/ReRateContainer'))
const MarketDataTestComponent = lazy(() => import('../pages/marketData-test/marketDataContainer'))
const Sbl = lazy(() => import('../pages/sblDashboard/sblContainer'))
const SblEfficiency = lazy(() => import('../pages/SblEfficiency/SblEfficiencyContainer'))
const SblSecurity = lazy(() => import('../pages/SblSecurity/SblSecurityContainer'))
const SblWrapper = lazy(() => import('../pages/sblDashboard-enhanced/sblWrapperContainer'))
const SecurityManager = lazy(() => import('../pages/SecurityManager/SecurityManagerContainer'))
const Simm = lazy(() => import('../pages/Simm/SimmContainer'))
const SettlementCalendar = lazy(() => import('../pages/settlementCalendar/settlementCalendarContainer'))
const SettlementLadder = lazy(() => import('../pages/settlementLadder/settlementLadderContainer'))
const SettlementLadderDetails = lazy(
	() => import('../pages/settlementLadder/settlementLadderDetails/settlementLadderDetailsContainer')
)
const StyleGuide = lazy(() => import('../pages/styleGuide/styleGuide'))
const SuccessReport = lazy(() => import('../pages/successReport/successReportContainer'))
const TradingQueue = lazy(() => import('../pages/tradingQueue/tradingQueueContainer'))
const TradeBlotter = lazy(() => import('../pages/TradeBlotter/TradeBlotterComponent'))
const TradeBlotterDynamic = lazy(() => import('../pages/TradeBlotterDynamic/TradeBlotterDynamicComponent'))
const Widgets = lazy(() => import('../pages/widgets/widgetsContainer'))
const CollateralOptimization = lazy(() => import('../pages/collateralOptimization/collateralOptimizationContainer'))
const DmnViewer = lazy(() => import('../pages/DmnViewer/DmnViewerContainer'))
const IntradayActivities = lazy(() => import('../pages/intradayActivities/intradayActivitiesContainer'))
const PbPortfolioManager = lazy(() => import('../pages/primeBroker/pbPortfolioManager/pbPortfolioManagerContainer'))
const PbNeedsAvailabilities = lazy(
	() => import('../pages/primeBroker/pbNeedsAndAvailabilities/pbNeedsAvailabilitiesContainer')
)
const PbAbcPortfolio = lazy(() => import('../pages/primeBroker/pbAbcPortfolio/pbAbcPortfolioContainer'))
const PbDashboard = lazy(() => import('../pages/primeBroker/pbDashboard/pbDashboardContainer'))
const MarketPlace = lazy(() => import('@swap/market-place/dist/index'))

export const paths = {
	accessDenied: '/404',
	iframe: '/iframe',
	abcmResult: '/abcm-analytics',
	abcmResultTable: '/abcm-details',
	abcPortfolio: '/abc-portfolio-test',
	abcPortfolioTest: '/abc-portfolio',
	abpm: '/abpm',
	accountSettings: '/account-settings',
	activityType: '/activity-type/:id',
	alp: '/alp',
	alpStt: '/alp1',
	alpClient: '/alp-client',
	alpSttClient: '/alp1-client',
	alpInternalAuctions: '/alp1-internal-auctions',
	alpBorrowerAuctions: '/alp1-borrower-auctions',
	alpInventoryManager: '/alp-inventory-manager',
	alpSttInventoryManager: '/alp1-inventory-manager',
	alpSecurityManager: '/alp-security-manager',
	basketItems: '/basket-items',
	boxes: '/boxes',
	breakevenPricer: '/pricing-tool',
	changePassword: '/change-password',
	clientScoring: '/client-scoring',
	collateralBasket: '/upsert-collateral-basket',
	collateralBasketList: '/collateral-basket',
	collateralEligibilityMatrix: '/collateral-eligibility-matrix',
	collateralOptimization: '/collateral-optimization',
	collateralOptimizationDashboard: '/decision-making-dashboard',
	counterpartyHaircut: '/counterparty-haircut',
	cusipSchedule: '/cusip-schedule',
	decisionMaking: '/decision-making',
	dummyWidget: '/kpi-widget',
	collateralOptWidget1: '/collateral-widget1',
	collateralOptWidget2: '/collateral-widget2',
	default: '/',
	defaultAuthenticated: '/dashboard-enhanced', // TEMP-FIX
	dashboard: '/dashboard',
	dashboardTest: '/dashboard-enhanced',
	dmnViewer: '/dmn-viewer',
	ErrorHandler: '/error',
	errorReport: getErrorReportPath(':docId'),
	forgotPassword: '/forgot-password',
	iconGuide: '/iconguide',
	inventory: '/inventory-manager',
	indexAnalysis: '/index-analysis',
	indexPricer: '/index-pricer',
	inventoryOptimization: '/inventory-optimization',
	lcr: '/lcr',
	intradayActivities: '/intraday-activities',
	collateralSchedule: '/collateralSchedule',
	login: '/login',
	marginAnalytics: '/margin-analytics',
	marginAnalysis: '/margin-analysis',
	marginAttribution: '/margin-attribution',
	marginAttributionWidget: '/margin-attribution-widget',
	matureTrades: '/mature-trades',
	marketPlace: '/market-place',
	needsAvailabilities: '/availabilities-needs',
	nonCash: '/nonCash',
	openTrades: '/open-trades',
	operations: '/operations',
	portfolioManager: '/portfolio-manager',
	resetPassword: '/reset-password/:resetToken',
	repo: '/repo',
	repoTest: '/repo-enhanced',
	reports: '/reports',
	rerate: '/re-rate',
	marketData: '/market-data',
	marginManagement: '/margin-management',
	pbAbcPortfolio: '/pb-abc-portfolio',
	pbDashboard: '/pb-dashboard',
	pbNeedsAvailabilities: '/pb-needs-availabilities',
	pbPortfolioManager: '/pb-portfolio-manager',
	peerToPeer: '/peer-to-peer',
	productMenu: '/product-menu',
	pdfViewer: '/pdf-viewer',
	sbl: '/sbl',
	sblEfficiency: '/sbl-efficiency',
	sblTest: '/sbl-enhanced',
	sblSecurity: '/sbl-security',
	securityManager: '/security-manager',
	simm: '/simm',
	settlementCalendar: '/maturity-calendar',
	settlementLadder: '/settlement-ladder',
	settlementLadderDetails: '/settlement-ladder-details',
	styleGuide: '/styleguide',
	successReport: getSuccessReportPath(':docId'),
	tradingQueue: '/trading-queue',
	tradeBlotter: '/trade-blotter',
	tradeBlotterDynamic: '/trade-blotter-dynamic',
	widgets: '/widgets',
	tenant: '/tenant',
}

export const routes: any = [
	{path: paths.accessDenied, component: AccessDenied},
	{path: paths.iframe, component: Iframe},
	{path: paths.default, component: Login},
	{path: paths.abcmResult, component: AbcmResult},
	{path: paths.abcPortfolio, component: AbcPortfolio},
	{path: paths.abcPortfolioTest, component: AbcPortfolioTest},
	{path: paths.abcmResultTable, component: AbcmReusltTable},
	{path: paths.abpm, component: Abpm},
	{path: paths.accountSettings, component: AccountSettings},
	{path: paths.activityType, component: ActivityType},
	{path: paths.alp, component: Locate},
	{path: paths.alpStt, component: AlpSttAuthorizer},
	{path: paths.alpClient, component: LocateClient},
	{path: paths.alpSttClient, component: AlpSttRequester},
	{path: paths.alpInternalAuctions, component: AlpAuctionsInternalMain},
	{path: paths.alpBorrowerAuctions, component: AlpAuctionsBorrowerMain},
	{path: paths.alpInventoryManager, component: LocateInventoryManager},
	{path: paths.alpSttInventoryManager, component: AlpSttInventoryManager},
	{path: paths.alpSecurityManager, component: LocateSecurityManager},
	{path: paths.basketItems, component: BasketItems},
	{path: paths.breakevenPricer, component: BreakEvenPricer},
	{path: paths.boxes, component: Boxes},
	{path: paths.changePassword, component: ChangePassword},
	{path: paths.clientScoring, component: ClientScoring},
	{path: paths.collateralBasket, component: CollateralBasket},
	{path: paths.collateralBasketList, component: CollateralBasketList},
	{path: paths.collateralEligibilityMatrix, component: CollateralEligibilityMatrix},
	{path: paths.collateralOptimization, component: CollateralOptimization},
	{path: paths.counterpartyHaircut, component: CounterpartyHaircut},
	{path: paths.cusipSchedule, component: CusipSchedule},
	{path: paths.dashboard, component: DashBoard},
	{path: paths.dashboardTest, component: DashboardWrapper},
	{path: paths.decisionMaking, component: DecisionMaking},
	{path: paths.dummyWidget, component: DummyWidget},
	{path: paths.collateralOptWidget1, component: CollateralOptWidget1},
	{path: paths.collateralOptWidget2, component: CollateralOptWidget2},
	{path: paths.marginAnalysis, component: MarginAnalysis},
	{path: paths.marginAttribution, component: MarginAttribution},
	{path: paths.marginAttributionWidget, component: MarginAttributionWidget},
	{path: paths.collateralOptimizationDashboard, component: DecisionMakingDashboard},
	{path: paths.ErrorHandler, component: ErrorHandler},
	{path: paths.errorReport, component: ErrorReport},
	{path: paths.forgotPassword, component: ForgotPassword},
	{path: paths.iconGuide, component: IconGuide},
	{path: paths.intradayActivities, component: IntradayActivities},
	{path: paths.inventory, component: Inventory},
	{path: paths.indexAnalysis, component: IndexAnalysis},
	{path: paths.indexPricer, component: IndexPricing},
	{path: paths.inventoryOptimization, component: InventoryOptimization},
	{path: paths.lcr, component: Lcr},
	{path: paths.collateralSchedule, component: CollateralSchedule},
	{path: paths.marginAnalytics, component: MarginAnalytics},
	{path: paths.marginManagement, component: MarginManagement},
	{path: paths.matureTrades, component: MaturingTrades},
	{path: paths.marketPlace, component: MarketPlace},
	{path: paths.needsAvailabilities, component: NeedsAvailabilities},
	{path: paths.nonCash, component: NonCash},
	{path: paths.openTrades, component: OpenTrades},
	{path: paths.operations, component: Operations},
	{path: paths.peerToPeer, component: PeerToPeer},
	{path: paths.portfolioManager, component: PortfolioManager},
	{path: paths.resetPassword, component: ForgotPassword},
	{path: paths.repo, component: Repo},
	{path: paths.rerate, component: Rerate},
	{path: paths.pbAbcPortfolio, component: PbAbcPortfolio},
	{path: paths.pbDashboard, component: PbDashboard},
	{path: paths.pbNeedsAvailabilities, component: PbNeedsAvailabilities},
	{path: paths.pbPortfolioManager, component: PbPortfolioManager},
	{path: paths.productMenu, component: ProductMenu},
	{path: paths.pdfViewer, component: PdfViewer},
	{path: paths.repoTest, component: RepoTest},
	{path: paths.reports, component: ReportsComponent},
	{path: paths.marketData, component: MarketDataTestComponent},
	{path: paths.sbl, component: Sbl},
	{path: paths.sblEfficiency, component: SblEfficiency},
	{path: paths.sblSecurity, component: SblSecurity},
	{path: paths.sblTest, component: SblWrapper},
	{path: paths.securityManager, component: SecurityManager},
	{path: paths.simm, component: Simm},
	{path: paths.settlementCalendar, component: SettlementCalendar},
	{path: paths.settlementLadder, component: SettlementLadder},
	{path: paths.settlementLadderDetails, component: SettlementLadderDetails},
	{path: paths.styleGuide, component: StyleGuide},
	{path: paths.successReport, component: SuccessReport},
	{path: paths.tradingQueue, component: TradingQueue},
	{path: paths.tradeBlotter, component: TradeBlotter},
	{path: paths.tradeBlotterDynamic, component: TradeBlotterDynamic},
	{path: paths.widgets, component: Widgets},
	{path: paths.dmnViewer, component: DmnViewer},
	{path: paths.tenant, component: Tenant},
]

export interface RouteConfig {
	id: string
	isBeta: boolean
	isDemo: boolean
	isSoon: boolean
	isAggregate?: boolean
	label: string
	labelTooltip?: string
	navigationMenuIcon: any
	path: string
	productMenuIcon: any
	configSpecific?: any
	notApplicableForMultiTenants?: boolean
	permission: string[]
}

interface protectedRouteListInterface {
	[key: string]: RouteConfig
}

export const protectedRouteList: protectedRouteListInterface = {
	abcmResultTable: {
		id: 'abcmResultTable',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Analytics',
		navigationMenuIcon: null,
		path: paths.abcmResultTable,
		productMenuIcon: null,
		permission: [permission.READ_PORTFOLIO, permission.WRITE_PORTFOLIO],
	},
	abcmAnalytics: {
		id: 'abcmAnalytics',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'ABCM Analytics',
		navigationMenuIcon: null,
		path: paths.abcmResult,
		productMenuIcon: <IconATYAbcmAnalytics />,
		permission: [permission.READ_PORTFOLIO_ABCM, permission.WRITE_PORTFOLIO_ABCM],
	},
	abcmResult: {
		id: 'abcmResult',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Analytics',
		navigationMenuIcon: null,
		path: '',
		productMenuIcon: null,
		permission: [permission.READ_PORTFOLIO, permission.WRITE_PORTFOLIO],
	},
	abcPortfolioTest: {
		id: 'abcPortfolioTest',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'ABC Portfolio',
		navigationMenuIcon: null,
		path: paths.abcPortfolioTest,
		productMenuIcon: <IconATYAbcPortfolio />,
		permission: [permission.READ_PORTFOLIO, permission.WRITE_PORTFOLIO],
	},
	sblEfficiency: {
		id: 'sblEfficiency',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'SBL Efficiency',
		navigationMenuIcon: null,
		path: paths.sblEfficiency,
		productMenuIcon: null,
		permission: [permission.READ_SBL_EFF],
	},
	abpm: {
		id: 'abpm',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'ABPM',
		navigationMenuIcon: null,
		path: paths.abpm,
		productMenuIcon: null,
		permission: [permission.READ_TEMPLATE_MANAGEMENT, permission.WRITE_TEMPLATE_MANAGEMENT],
	},
	accountSettings: {
		id: 'accountSettings',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Account Settings',
		navigationMenuIcon: null,
		path: paths.accountSettings,
		productMenuIcon: <IconAccountSettingsGear />,
		permission: [permission.READ_ORG_SETUP, permission.WRITE_ORG_SETUP, permission.WRITE_USER],
	},
	algorithmicAnalytics: {
		id: 'algorithmicAnalytics',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Treasury and Liquidity Analytics',
		navigationMenuIcon: null,
		path: paths.abcmResult,
		productMenuIcon: <AlgorithmicAnalytics />,
		permission: [permission.READ_PORTFOLIO_ABCM, permission.WRITE_PORTFOLIO_ABCM],
	},
	alp: {
		id: 'alp',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		isAggregate: true,
		label: 'Authorizer',
		navigationMenuIcon: null,
		path: paths.alp,
		productMenuIcon: <IconLocateSearch />,
		configSpecific: ['finOptSys', 'stateStreet'],
		permission: [permission.LOCATE_PROVIDER],
	},
	alpStt: {
		id: 'alpStt',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		isAggregate: true,
		label: 'Order Book',
		labelTooltip: 'Order Book',
		navigationMenuIcon: null,
		path: paths.alpStt,
		productMenuIcon: <IconLocateSearch />,
		configSpecific: ['stateStreetAlp'],
		permission: [permission.LOCATE_PROVIDER],
	},
	alpClient: {
		id: 'alpClient',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		isAggregate: true,
		label: 'Requester',
		navigationMenuIcon: null,
		path: paths.alpClient,
		productMenuIcon: <IconLocateSearch />,
		permission: [permission.LOCATE_SEEKER],
	},
	alpSttClient: {
		id: 'alpSttClient',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		isAggregate: true,
		label: 'Order Book',
		labelTooltip: 'Order Book',
		navigationMenuIcon: null,
		path: paths.alpSttClient,
		productMenuIcon: <IconLocateSearch />,
		configSpecific: ['stateStreetAlp'],
		permission: [permission.LOCATE_SEEKER],
	},
	alpAuctionsInternalMain: {
		id: 'alpAuctionsInternalMain',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		isAggregate: true,
		label: 'Auction Dashboard',
		labelTooltip: 'Auction Dashboard',
		navigationMenuIcon: null,
		path: paths.alpInternalAuctions,
		productMenuIcon: <IconLocateSearch />,
		configSpecific: ['stateStreetAlp'],
		permission: [permission.AUCTION_PROVIDER],
	},
	alpAuctionsBorrowerMain: {
		id: 'alpAuctionsBorrowerMain',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		isAggregate: true,
		label: 'Auction Dashboard',
		labelTooltip: 'Auction Dashboard',
		navigationMenuIcon: null,
		path: paths.alpBorrowerAuctions,
		productMenuIcon: <IconLocateSearch />,
		configSpecific: ['stateStreetAlp'],
		permission: [permission.AUCTION_SEEKER],
	},
	alpInventoryManager: {
		id: 'alpInventoryManager',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		isAggregate: true,
		label: 'Inventory Manager',
		navigationMenuIcon: null,
		path: paths.alpInventoryManager,
		productMenuIcon: <IconLocateSearch />,
		configSpecific: ['finOptSys', 'stateStreet'],
		permission: [permission.LOCATE_PROVIDER],
	},
	alpSttInventoryManager: {
		id: 'alpSttInventoryManager',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		isAggregate: true,
		label: 'Browse Availability',
		navigationMenuIcon: null,
		path: paths.alpSttInventoryManager,
		productMenuIcon: <IconLocateSearch />,
		configSpecific: ['stateStreetAlp'],
		permission: [permission.LOCATE_PROVIDER, permission.LOCATE_SEEKER],
	},
	alpSecurityManager: {
		id: 'alpSecurityManager',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		isAggregate: true,
		label: 'Security Manager',
		navigationMenuIcon: null,
		path: paths.alpSecurityManager,
		productMenuIcon: null,
		permission: [permission.LOCATE_PROVIDER],
	},
	analytics: {
		id: 'analytics',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Analytics',
		navigationMenuIcon: <IconAnalytics />,
		path: '',
		productMenuIcon: null,
		permission: [],
	},
	breakevenPricer: {
		id: 'breakevenPricer',
		isBeta: false,
		isDemo: false,
		isSoon: false,
		label: 'Repo Pricer',
		navigationMenuIcon: null,
		path: paths.breakevenPricer,
		productMenuIcon: null,
		permission: [permission.FEATURES_IN_BETA],
	},
	changePassword: {
		id: 'changePassword',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Change Password',
		navigationMenuIcon: null,
		path: paths.changePassword,
		productMenuIcon: <IconDashboardChangePassword />,
		permission: [],
	},
	clientScoring: {
		id: 'clientScoring',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Client Scoring',
		navigationMenuIcon: null,
		path: paths.clientScoring,
		productMenuIcon: <IconClientScoring />,
		permission: [permission.READ_CLIENT_SCORING, permission.WRITE_CLIENT_SCORING],
	},
	collateralBasket: {
		id: 'collateralBasket',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Collateral Basket',
		navigationMenuIcon: null,
		path: paths.collateralBasket,
		productMenuIcon: null,
		permission: [permission.WRITE_COLLATERAL_BASKET],
	},
	collateralBasketItems: {
		id: 'collateralBasketItems',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Collateral Basket Items',
		navigationMenuIcon: null,
		path: paths.basketItems,
		productMenuIcon: null,
		permission: [permission.READ_COLLATERAL_BASKET, permission.WRITE_COLLATERAL_BASKET],
	},
	collateralBasketList: {
		id: 'collateralBasketList',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Collateral Basket',
		navigationMenuIcon: null,
		path: paths.collateralBasketList,
		productMenuIcon: <IconPCCollateralBasket />,
		permission: [permission.READ_COLLATERAL_BASKET, permission.WRITE_COLLATERAL_BASKET],
	},
	collateralEligibilityMatrix: {
		id: 'collateralEligibilityMatrix',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Collateral Eligibility',
		navigationMenuIcon: null,
		path: paths.collateralEligibilityMatrix,
		productMenuIcon: null,
		permission: [permission.READ_TEMPLATE_MANAGEMENT, permission.WRITE_TEMPLATE_MANAGEMENT],
	},
	collateralOptimization: {
		id: 'collateralOptimization',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Collateral Optimization',
		navigationMenuIcon: null,
		path: paths.collateralOptimization,
		productMenuIcon: null,
		permission: [permission.READ_COLLATERAL_OPTIMIZER, permission.WRITE_COLLATERAL_OPTIMIZER],
	},
	collateralOptimizationDashboard: {
		id: 'collateralOptimizationDashboard',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Collateral Optimization Dashboard',
		navigationMenuIcon: null,
		path: paths.collateralOptimizationDashboard,
		productMenuIcon: <IconIQDecisionMaking />,
		permission: [permission.READ_COLLATERAL_OPTIMIZER, permission.WRITE_COLLATERAL_OPTIMIZER],
	},
	configuration: {
		id: 'configuration',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Configuration',
		navigationMenuIcon: null,
		path: '',
		productMenuIcon: <Configuration />,
		permission: [],
	},
	counterpartyHaircut: {
		id: 'counterpartyHaircut',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Counterparty Haircut',
		navigationMenuIcon: null,
		path: paths.counterpartyHaircut,
		productMenuIcon: null,
		permission: [],
	},
	cusipSchedule: {
		id: 'cusipSchedule',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Cusip Schedule',
		navigationMenuIcon: null,
		path: paths.cusipSchedule,
		productMenuIcon: null,
		permission: [permission.READ_TEMPLATE_MANAGEMENT, permission.WRITE_TEMPLATE_MANAGEMENT],
	},
	dashboard: {
		id: 'dashboard',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Dashboard',
		navigationMenuIcon: <IconDashboard />,
		path: paths.dashboard,
		productMenuIcon: null,
		permission: [permission.READ_PORTFOLIO, permission.WRITE_PORTFOLIO],
	},
	dashboardTest: {
		id: 'dashboard',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Dashboard',
		navigationMenuIcon: <IconDashboard />,
		path: paths.dashboardTest,
		productMenuIcon: <IconDashboard />,
		permission: [permission.READ_PORTFOLIO, permission.WRITE_PORTFOLIO],
	},
	dataMining: {
		id: 'dataMining',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Data Mining',
		navigationMenuIcon: <IconDataMining />,
		path: '',
		productMenuIcon: <DataMining />,
		permission: [],
	},
	decisionMaking: {
		id: 'decisionMaking',
		isBeta: true,
		isDemo: false,
		isSoon: false,
		label: 'Decision Making',
		navigationMenuIcon: null,
		path: paths.decisionMaking,
		productMenuIcon: <IconIQDecisionMaking />,
		permission: [permission.READ_DECISION_MAKING, permission.WRITE_DECISION_MAKING],
	},
	digitalTransformation: {
		id: 'digitalTransformation',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Digital Transformation',
		navigationMenuIcon: null,
		path: '',
		productMenuIcon: <DigitalTransformation />,
		permission: [],
	},
	dmnViewer: {
		id: 'dmnViewer',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'DMN Template',
		navigationMenuIcon: null,
		path: paths.dmnViewer,
		productMenuIcon: null,
		permission: [
			permission.FEATURES_DEPRECATED,
			permission.READ_TEMPLATE_MANAGEMENT,
			permission.WRITE_TEMPLATE_MANAGEMENT,
		],
	},
	execution: {
		id: 'execution',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Execution',
		navigationMenuIcon: <IconExecution />,
		path: '',
		productMenuIcon: null,
		permission: [],
	},
	group: {
		id: 'group',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Group',
		navigationMenuIcon: null,
		path: '',
		productMenuIcon: null,
		permission: [],
	},
	indexAnalysis: {
		id: 'indexAnalysis',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Index Trading & Analytics',
		navigationMenuIcon: null,
		path: paths.indexAnalysis,
		productMenuIcon: <IconIndexTradingAndAnalytics />,
		permission: [permission.FEATURES_IN_BETA],
	},
	indexPricer: {
		id: 'indexPricer',
		isBeta: false,
		isDemo: false,
		isSoon: false,
		label: 'Index Pricer',
		navigationMenuIcon: null,
		path: paths.indexPricer,
		productMenuIcon: <IconAYTPricingTool />,
		permission: [permission.FEATURES_IN_BETA],
	},
	intradayActivities: {
		id: 'intradayActivities',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Intraday Activity',
		navigationMenuIcon: null,
		path: paths.intradayActivities,
		productMenuIcon: null,
		permission: [permission.FEATURES_IN_BETA],
	},
	inventory: {
		id: 'inventory',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Inventory Manager',
		navigationMenuIcon: null,
		path: paths.inventory,
		productMenuIcon: <IconATYInventoryManagemnet />,
		permission: [permission.FEATURES_IN_BETA],
	},
	inventoryOptimization: {
		id: 'inventoryOptimization',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Inventory Optimization',
		navigationMenuIcon: null,
		path: paths.inventoryOptimization,
		productMenuIcon: <IconIQInventoryOptimization />,
		permission: [
			permission.FEATURES_IN_BETA,
			permission.READ_INVENTORY_OPTIMIZER,
			permission.WRITE_INVENTORY_OPTIMIZER,
		],
	},
	lcr: {
		id: 'lcr',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'LCR',
		navigationMenuIcon: null,
		path: paths.lcr,
		productMenuIcon: null,
		permission: [permission.READ_TEMPLATE_MANAGEMENT, permission.WRITE_TEMPLATE_MANAGEMENT],
	},
	tradeBlotter: {
		id: 'tradeBlotter',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Trade Blotter',
		navigationMenuIcon: null,
		path: paths.tradeBlotter,
		productMenuIcon: null,
		permission: [permission.READ_TRADE_BLOTTER, permission.WRITE_TRADE_BLOTTER],
	},
	tradeBlotterDynamic: {
		id: 'tradeBlotterDynamic',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Trade Blotter Dynamic',
		navigationMenuIcon: null,
		path: paths.tradeBlotterDynamic,
		productMenuIcon: null,
		permission: [],
	},
	managementDashboard: {
		id: 'managementDashboard',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Management Dashboard',
		navigationMenuIcon: null,
		path: '',
		productMenuIcon: <ManagementDashboard />,
		permission: [],
	},
	marketData: {
		id: 'marketData',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Reports Dashboard',
		navigationMenuIcon: null,
		path: paths.marketData,
		productMenuIcon: <IconAYTMarketData />,
		notApplicableForMultiTenants: true,
		permission: [permission.READ_DATA_MINING, permission.READ_REPO_DBRD, permission.WRITE_REPO_DBRD],
	},
	mySubscriptions: {
		id: 'mySubscriptions',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'My Subscriptions',
		navigationMenuIcon: null,
		path: '',
		productMenuIcon: <MySubscriptions />,
		permission: [],
	},
	needsAvailabilities: {
		id: 'needsAvailabilities',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'AV & N',
		navigationMenuIcon: null,
		path: paths.needsAvailabilities,
		productMenuIcon: <IconExeAvailabilityAndNeed />,
		permission: [permission.READ_AVAILABILITY_NEEDS_DOCUMENT, permission.WRITE_AVAILABILITY_NEEDS_DOCUMENT],
	},
	nonCash: {
		id: 'nonCash',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Non Cash',
		navigationMenuIcon: null,
		path: paths.nonCash,
		productMenuIcon: null,
		permission: [permission.READ_TEMPLATE_MANAGEMENT, permission.WRITE_TEMPLATE_MANAGEMENT],
	},
	operations: {
		id: 'operations',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Operations',
		navigationMenuIcon: <IconOperations />,
		path: paths.operations,
		productMenuIcon: <IconOperationsMenu />,
		permission: [permission.READ_OPERATIONS, permission.WRITE_OPERATIONS],
	},
	P2PPlatform: {
		id: 'P2PPlatform',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'P2P Platform',
		navigationMenuIcon: null,
		path: '',
		productMenuIcon: <P2PPlatform />,
		permission: [],
	},
	peerToPeer: {
		id: 'peerToPeer',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'P2P',
		navigationMenuIcon: null,
		path: paths.peerToPeer,
		productMenuIcon: <IconExeP2P />,
		permission: [permission.READ_P2P, permission.WRITE_P2P],
	},
	marginManagement: {
		id: 'marginManagement',
		isBeta: true,
		isDemo: true,
		isSoon: false,
		label: 'Margin Management',
		navigationMenuIcon: null,
		path: paths.marginManagement,
		productMenuIcon: null,
		permission: [permission.READ_MARGIN_MANAGEMENT, permission.WRITE_MARGIN_MANAGEMENT],
	},
	marginAnalysis: {
		id: 'marginAnalysis',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Margin Analysis',
		navigationMenuIcon: null,
		path: paths.marginAnalysis,
		productMenuIcon: null,
		permission: [permission.READ_MARGIN_ANALYSIS, permission.WRITE_MARGIN_ANALYSIS],
	},
	marginAttribution: {
		id: 'marginAttribution',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Margin Attribution',
		navigationMenuIcon: null,
		path: paths.marginAttribution,
		productMenuIcon: null,
		permission: [permission.READ_MARGIN_ATTRIBUTION, permission.WRITE_MARGIN_TTRIBUTION],
	},
	marketPlace: {
		id: 'marketPlace',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Market Place',
		navigationMenuIcon: <IconOperations />,
		path: paths.marketPlace,
		productMenuIcon: <IconExeP2P />,
		permission: [permission.READ_SWAP_MARKETPLACE, permission.WRITE_SWAP_MARKETPLACE],
	},
	portfolioAnalytics: {
		id: 'portfolioAnalytics',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Portfolio Analytics',
		navigationMenuIcon: <IconPortfolioAnalytics />,
		path: '',
		productMenuIcon: <PortfolioAnalytics />,
		permission: [],
	},
	portfolioManager: {
		id: 'portfolioManager',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Portfolio Manager',
		navigationMenuIcon: <IconPortfolioManagerTwo />,
		path: '',
		productMenuIcon: <IconPortfolioManager />,
		permission: [],
	},
	pricingTool: {
		id: 'pricingTool',
		isBeta: false,
		isDemo: false,
		isSoon: false,
		label: 'Pricing Tool',
		navigationMenuIcon: <IconPricingTool />,
		path: paths.breakevenPricer,
		productMenuIcon: <IconAYTPricingTool />,
		permission: [],
	},
	quantitativeIntelligence: {
		id: 'quantitativeIntelligence',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Quantitative Intelligence',
		navigationMenuIcon: <IconQuantitativeIntelligence />,
		path: '',
		productMenuIcon: <QuantitativeIntelligence />,
		permission: [],
	},
	repo: {
		id: 'repo',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Repo',
		navigationMenuIcon: null,
		path: paths.repo,
		productMenuIcon: <IconExeRepo />,
		// configSpecific: ['finOptSys', 'tradeUp', 'stateStreet'], // Removing as we have individual permissions now
		permission: [permission.READ_REPO, permission.WRITE_REPO],
	},
	sbl: {
		id: 'sbl',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'SBL',
		navigationMenuIcon: null,
		path: paths.sbl,
		productMenuIcon: <IconExeSBL />,
		permission: [permission.READ_SBL, permission.WRITE_SBL],
	},
	securityManager: {
		id: 'securityManager',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Security Manager',
		navigationMenuIcon: <IconPortfolioManagerTwo />,
		path: paths.securityManager,
		productMenuIcon: null,
		permission: [],
	},
	settlementCalendar: {
		id: 'settlementCalendar',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Maturity Calendar',
		navigationMenuIcon: null,
		path: paths.settlementCalendar,
		productMenuIcon: <IconAYTMaturityCalendar />,
		permission: [permission.READ_CALENDER, permission.WRITE_CALENDER],
	},
	settlementLadder: {
		id: 'settlementLadder',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Settlement Ladder',
		navigationMenuIcon: null,
		path: paths.settlementLadder,
		productMenuIcon: <IconAYTSettlementLadder />,
		// configSpecific: ['finOptSys', 'tradeUp', 'stateStreet'], // Removing as we have individual permissions now
		permission: [permission.READ_SETTLEMENT_LADDER, permission.WRITE_SETTLEMENT_LADDER],
	},
	settlementLadderDetails: {
		id: 'settlementLadderDetails',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Settlement Ladder Details',
		navigationMenuIcon: null,
		path: paths.settlementLadderDetails,
		productMenuIcon: null,
		permission: [permission.READ_SETTLEMENT_LADDER, permission.WRITE_SETTLEMENT_LADDER],
	},
	simm: {
		id: 'simm',
		isBeta: false,
		isDemo: false,
		isSoon: true,
		label: 'SIMM Analytics',
		navigationMenuIcon: null,
		path: paths.simm,
		productMenuIcon: <IconIQSIMM />,
		permission: [permission.FEATURES_IN_BETA],
	},
	tenant: {
		id: 'tenant',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Tenant',
		navigationMenuIcon: null,
		path: paths.tenant,
		productMenuIcon: <IconATYAbcPortfolio />,
		permission: [],
	},
	templateManagement: {
		id: 'templateManagement',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Template Management',
		navigationMenuIcon: <IconPricingTool />,
		path: '',
		productMenuIcon: null,
		permission: [],
	},
	tradingQueue: {
		id: 'tradingQueue',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Trading Queue',
		navigationMenuIcon: null,
		path: paths.tradingQueue,
		productMenuIcon: <IconExeTradingQueue />,
		// configSpecific: ['finOptSys', 'tradeUp', 'stateStreet'], // Removing as we have individual permissions now
		permission: [permission.READ_TRADING_QUEUE, permission.WRITE_TRADING_QUEUE],
	},
	uploadPortfolio: {
		id: 'uploadPortfolio',
		isBeta: false,
		isDemo: true,
		isSoon: false,
		label: 'Upload Portfolio',
		navigationMenuIcon: <IconPortfolioManagerTwo />,
		path: paths.portfolioManager,
		productMenuIcon: null,
		permission: [
			permission.READ_PORTFOLIO_DOCUMENT,
			permission.WRITE_PORTFOLIO_DOCUMENT,
			permission.READ_RESTRICTED_SECURITY_DOCUMENT,
			permission.WRITE_RESTRICTED_SECURITY_DOCUMENT,
		],
	},
}
